import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import config from '@data/config.json';
import styled from 'styled-components';
import {
  Hero,
  Box,
  Flex,
  Container,
  theme,
} from '@components';

const WarnSpan = styled.span`
  font-size: 0.8em;
  color: ${theme.colors.yellow};
`;

const Map = styled.div`
  width: 100%;
  background-color: ${theme.colors.lightBackground};
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height: 0;

  & iframe {
    left:0;
    top:0;
    height:100%;
    max-height: 600px;
    width:100%;
    position:absolute;
  }
`;

const { number = {} } = config;

const ContactPage = ({ data }) => {
  const { frontmatter = {} } = data.markdownRemark;

  const {
    hero,
    seo,
    contactData,
  } = frontmatter;

  return (
    <>
      <Hero
        {...hero}
        seo={seo}
        space={{
          pb: [2, 3],
        }}
      />
      <Container py={0} mb={[2, 3, 4]}>
        <Flex>
          <Box
            width={[1, 1, 1 / 3]}
            display={['none', 'none', 'block']}
          />
          <Box
            width={[1, 1, 2 / 3]}
            textAlign={['center', 'center', 'initial']}
          >
            {contactData.map(({
              title, href, text, warning,
            }) => (
              <Box
                key={`${title}-${href}`}
                width={1}
                fontSize={[2, 3, 4]}
              >
                <strong>{title}</strong>
                {': '}
                <span>
                  {href ? <a href={href}>{text}</a> : text}
                  {warning && (
                    <WarnSpan>
                      {' '}
                      {warning}
                    </WarnSpan>
                  )}
                </span>
              </Box>
            ))}

            <Box
              width={1}
              fontSize={[2, 3, 4]}
            >
              <strong>{number.title}</strong>
              {': '}
              <span className="number">
                <a href={number.href}>{number.text}</a>
                {` ${number.info}`}
              </span>
            </Box>
          </Box>
        </Flex>
      </Container>
      <Map>
        <iframe
          title="map"
          src="https://maps.google.com/maps?q=mi%C4%99dzyleska%204&t=&z=15&ie=UTF8&iwloc=&output=embed"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
      </Map>
    </>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          markdown
        }
        contactData {
          title
          href
          text
        }
        seo {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
